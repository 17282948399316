import React from "react"
import { Link, graphql } from "gatsby"
import dateFormat from 'dateformat';
import Img from 'gatsby-image';

import MainContent from "../components/MainContent"
import Pagination from "../components/Pagination";
import RichText from "../components/RichText";
import Seo from "../components/Seo";
import { AccessTime } from "../images/svg-icons"
import Header from '../components/Header';
import Footer from '../components/Footer';


function BlogListItem({ node }) {
  const { title, summary, slug, featuredImage, createdAt } = node;

  const hasImg = featuredImage && featuredImage.fluid;
  const blogUrl = `/blog/${slug}`;

  return (
    <article className="blogListItem">
      <h2><Link to={blogUrl}>{title}</Link></h2>
      {
        hasImg &&
        <Link to={blogUrl}><Img fluid={featuredImage.fluid} alt={featuredImage.title} /></Link>
      }
      <div className="markdown">
        <RichText data={summary} />
      </div>
      <p className="time"><AccessTime />{dateFormat(createdAt, 'dddd, mmmm dS, yyyy, h:MM:ss TT')}</p>
    </article>
  );
}

function BlogList({ data, location, pageContext }) {
  const { pageNumber, totalCount, itemsPerPage } = pageContext;
  const imageNode = data.allFile.nodes && data.allFile.nodes.length > 0 ? data.allFile.nodes[0] : null;
  const maxPage = Math.ceil(totalCount / itemsPerPage);

  if (pageNumber > maxPage) {
    return null;
  }

  let pageSuffix = pageNumber > 1 ? ` - Page ${pageNumber}` : '';
  const h1Text = `Latest eWallet App News & Articles at GeteWallet Blog${pageSuffix}`;
  const metaTitleText = `eWallet App – News, Blog Posts & Latest Articles${pageSuffix}`;

  return (
    <>
      <Header />
      {
        imageNode
          ? <div className="fullWidthContainer">
              <Img fluid={imageNode.childImageSharp.fluid} alt="Blog banner" />
            </div>
          : null
      }
      <MainContent pathname={location.pathname}>
        <Seo
          title={metaTitleText}
          description="Get the latest information related to eWallet applications and payment services. Informative articles, blog posts and latest news at GeteWallet Blog."
          canonical={location.pathname.substr(1)}
        />
        <h1>{h1Text}</h1>
        {data.allContentfulBlog.edges.map(({ node }) => {
          return <BlogListItem key={node.slug} node={node} />;
        })}
        <Pagination
          currentPage={pageNumber}
          totalCount={totalCount}
          itemsPerPage={itemsPerPage}
          urlCallback={p => p === 1 ? '/blog' : `/blog/${p}`}
        />
      </MainContent>
      <Footer />
    </>
  );
}

export default BlogList;

export const pageQuery = graphql`
  query ($skip: Int!, $itemsPerPage: Int!, $imgQuality: Int!, $traceSvgColor: String!) {
    allContentfulBlog(skip: $skip, limit: $itemsPerPage, sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          createdAt
          slug
          title
          summary {
            raw
          }
          featuredImage {
            title
            fluid(
              maxWidth: 912,
              quality: $imgQuality,
              background: $traceSvgColor
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    allFile(filter: {sourceInstanceName: {eq: "images"}, relativePath: {eq: "blog-news-hero.jpg"}}) {
      nodes {
        childImageSharp {
          fluid(
            maxWidth: 1440,
            traceSVG: {
              color: $traceSvgColor
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;
